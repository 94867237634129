import { CarSkeleton } from './subcomponent/CarSkeleton/CarSkeleton';

import MBBeVoContentSlider from './subcomponent/MBBeVoContentSlider/MBBeVoContentSlider';
import MBBeVoDesc from './subcomponent/MBBeVoDesc/MBBeVoDesc';
import './MainPage.css';
import { CarSkeletonSmartphone } from './subcomponent/CarSkeletonSmartphone/CarSkeletonSmartphone';
import { CarSkeletonTablet } from './subcomponent/CarSkeletonTablet/CarSkeletonTablet';
import { useEffect } from 'react';

/**
 * Main page (landing page or home page) component.
 *
 * @returns
 */

export function MainPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Slider for desktop devices > 992px */}
      <CarSkeleton></CarSkeleton>
      {/* Slider for tablet devices > 768px < 992px */}
      <CarSkeletonTablet></CarSkeletonTablet>
      {/* Slider for smartphone devices < 768px */}
      <CarSkeletonSmartphone></CarSkeletonSmartphone>

      <MBBeVoContentSlider></MBBeVoContentSlider>

      {/* MBBeVoDesc */}
      <MBBeVoDesc></MBBeVoDesc>
    </>
  );
}
