/* eslint-disable */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core';
import './Prolog.css';

import { useTranslation } from 'react-i18next';

// Styling
const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      color: 'white',
    },
  })
);

export default function Prolog(props: { description: string }) {
  const [expand, setExpand] = useState(false);
  const [text, setText] = useState('');

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setExpand(false);
    // Update the document title using the browser API

    if (props.description === 'Motoröl' || props.description === 'EngineOil') {
      setText(t('PrologMotorOil'));
    } else if (
      props.description === 'Kraftstoff' ||
      props.description === 'Fuel'
    ) {
      setText(t('PrologFuel'));
    } else if (
      props.description === 'Getriebeöl' ||
      props.description === 'GearOil'
    ) {
      setText(t('PrologGearOil'));
    } else if (props.description === 'AdBlue' || props.description === 'N0x') {
      setText(t('PrologAdBlue'));
    } else if (
      props.description === 'Coolant' ||
      props.description === 'Frostschutz' ||
      props.description === 'Korrosions-/Frostschutz'
    ) {
      setText(t('PrologCoolant'));
    } else if (
        props.description === "edAC-Oil" ||
        props.description === "edAC-öl"
    ) {
        setText(t('PrologEdac'));
    } else {
      setText('');
    }
  }, [i18n.language, props.description]);

  return (
    <>
      {text != '' && (
        <div className='prolog'>
          <div
            className={clsx('accordionContainer', {
              ['accordionExpended']: expand,
              ['accordionShrinked']: !expand,
              ['prolog__transition']: !expand,
            })}
          >
            <div className='prologWidth prologStyle prolog'>
              <span className='wb-type-copy prolog' dangerouslySetInnerHTML={{__html: text}}/>
            </div>
          </div>

          <button
            onClick={() => setExpand(!expand)}
            type='button'
            className='accordionButton wb-button wb-button--theme-dark wb-button--secondary wb-button--large'
          >
            {expand ? t('ShowLess') : t('ShowMore')}
          </button>
        </div>
      )}
    </>
  );
}
