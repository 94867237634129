/* eslint-disable */

import {createStyles, makeStyles} from '@material-ui/core';
import drop from '../../../resource/icons/ic_bevo_drop_16_grey.svg';
import dropWhite from '../../../resource/icons/ic_bevo_drop_16.svg';
import './header_overwrite.css';
import './header.css';

import '../../../globals/style/GlobalStyle.css';
import {useTranslation, Trans} from 'react-i18next';
import {NavWeb} from './subcomponent/navWeb/NavWeb';
import {SearchOverview} from '../search/SearchOverview';
import {Link} from 'react-router-dom';
import {
	openCategoriesMenu,
	closeCategoriesMenu,
	showProviderTab,
} from '../../../utility/DialogHelper';
import {useEffect, useState} from 'react';

// Styling
const useStyles = makeStyles(() =>
	createStyles({
		icon: {
			color: 'white',
		},
	})
);

export default function Header() {
	const classes = useStyles();
	const {t, i18n} = useTranslation();

	useEffect(() => {
		i18n.language === 'de' ? setLanguageDe(null) : setLanguageEn(null);
	}, []);

	const setLanguageDe = (e) => {
		e?.preventDefault();
		i18n.changeLanguage('de');
		document
			?.getElementById('deSwitch')
			?.classList?.add('wb-language-menu__item--active');
		document
			?.getElementById('enSwitch')
			?.classList?.remove('wb-language-menu__item--active');
		document
			?.getElementById('deSwitchMobile')
			?.classList?.add('wb-language-menu__item--active');
		document
			?.getElementById('enSwitchMobile')
			?.classList?.remove('wb-language-menu__item--active');
	};

	const setLanguageEn = (e) => {
		e?.preventDefault();
		i18n.changeLanguage('en');
		document
			?.getElementById('enSwitch')
			?.classList?.add('wb-language-menu__item--active');
		document
			?.getElementById('deSwitch')
			?.classList?.remove('wb-language-menu__item--active');
		document
			?.getElementById('enSwitchMobile')
			?.classList?.add('wb-language-menu__item--active');
		document
			?.getElementById('deSwitchMobile')
			?.classList?.remove('wb-language-menu__item--active');
	};

	const openSearch = (e) => {
		e.preventDefault();
		closeCategoriesMenu();

		document
			.getElementsByClassName('.wb-header__logo-container')[0]
			?.classList.add('foreground');

		document.getElementById('searchContainer')?.classList.add('visible');

		document.getElementById('searchContainer')?.classList.remove('notVisible');

		document.getElementById('deactivedBackground')?.classList.add('visible');
		document.getElementById('deactivedBackground')?.classList.add('darkColor');

		document
			.getElementById('deactivedBackground')
			?.classList.remove('notVisible');
	};

	return (
		<>
			<SearchOverview></SearchOverview>
			<div className='headerContainer'>
				<wb-headers logo-href='/'>
					<div className="wb-header__content-width">
						<div className="wb-header__logo-container">
							<a className="wb-header__logo" href="/">
								<img src="/MB-star_n_web.svg"  className="wb-header__star" />
								<div className="wb-header__claim-wrapper">
									<img src="/mbo_claim_64_n_web-01.svg" className="wb-header__claim"/>
								</div>
							</a>
						</div>
						<div className="wb-header__nav-container" role="navigation">
					<nav
						className='wb-header-nav wb-header-nav--desktop wb-header-nav--meta'
						aria-label='Meta Navigation'
					>
						<nav
							className='wb-language-menu foreground'
							aria-label='Language Navigation'
						>
							<a
								onClick={setLanguageDe}
								id='deSwitch'
								className='wb-language-menu__item'
							>
								<div className='wb-language-menu__item-inner'>
									<span className='wb-visually-hidden'>Select Language:</span>
									<span>DE</span>
								</div>
							</a>
							<a
								onClick={setLanguageEn}
								id='enSwitch'
								className='wb-language-menu__item wb-language-menu__item--active'
							>
								<div className='wb-language-menu__item-inner'>
									<span className='wb-visually-hidden'>Select Language:</span>
									<span>EN</span>
								</div>
							</a>
						</nav>
						<button
							class='wb-header-nav__control wb-header-nav__control--icon-only'
							type='button'
							onClick={openSearch}
						>
							<wb-icon name='search' aria-hidden='true'></wb-icon>
							<span class='wb-visually-hidden'>ADD BUTTON TEXT</span>
						</button>
					</nav>

					<nav
						class='wb-header-nav wb-header-nav--desktop wb-header-nav--main'
						aria-label='Main Navigation (First Level)'
					>
						<button
							class='wb-header-nav__control wb-header-nav__control--offset-start'
							type='button'
							onClick={openCategoriesMenu}
						>
							{/* <img
                style={{ width: '16px', marginRight: '8px' }}
                src={drop}
              ></img> */}
							<wb-icon src={drop} aria-hidden='true'></wb-icon>
							{t('Categories')}
						</button>
						{/*             <button class='wb-header-nav__control' href='#'>
              {t('OilFinder')}
            </button> */}
						<a
							className='wb-header-nav__control highlightBgColor'
							href={i18n.language === 'en' ? "/DTFR_Liste_english.pdf": "/DTFR_Liste_deutsch.pdf" }
							target='_blank'
						>
							{t('MappingOverview')}
						</a>
						<a
							class='wb-header-nav__control'
							onClick={showProviderTab}
							target='_blank'
						>
							{t('Provider')}
						</a>
						<Link class='wb-header-nav__control' to={'/contact'}>
							{t('Contact')}
						</Link>
					</nav>

					<nav
						class='wb-header-nav wb-header-nav--tablet wb-header-nav--start'
						aria-label='Main Navigation'
					>
						<button
							class='wb-header-nav__control wb-header-nav__control--icon-only'
							type='button'
							onClick={openCategoriesMenu}
						>
							<wb-icon src={dropWhite} aria-hidden='true'></wb-icon>
							<span class='wb-visually-hidden'>Categories</span>
						</button>
					</nav>

					<nav
						class='wb-header-nav wb-header-nav--mobile wb-header-nav--start mobileHeader'
						aria-label='Main Navigation Controls'
					>
						<button
							class='wb-header-nav__control wb-header-nav__control--icon-only'
							type='button'
							onClick={openCategoriesMenu}
						>
							<wb-icon src={dropWhite} aria-hidden='true'></wb-icon>
							<span class='wb-visually-hidden'>Kategorien</span>
						</button>
					</nav>
					<nav
						class='wb-language-menu foreground mobileLanguageSwitch notDesktop'
						aria-label='Language Navigation'
					>
						<a
							onClick={setLanguageDe}
							id='deSwitchMobile'
							class='wb-language-menu__item mobileLanguageItem'
						>
							<div class='wb-language-menu__item-inner '>
								<span class='wb-visually-hidden'>Select Language:</span>
								<span>DE</span>
							</div>
						</a>
						<a
							onClick={setLanguageEn}
							id='enSwitchMobile'
							class='wb-language-menu__item wb-language-menu__item--active mobileLanguageItem'
						>
							<div class='wb-language-menu__item-inner '>
								<span class='wb-visually-hidden'>Select Language:</span>
								<span>EN</span>
							</div>
						</a>
					</nav>
					<button
						class='wb-header-nav__control wb-header-nav__control--icon-only notDesktop'
						type='button'
						onClick={openSearch}
					>
						<wb-icon name='search' aria-hidden='true'></wb-icon>
						<span class='wb-visually-hidden'>ADD BUTTON TEXT</span>
					</button>
						</div>
					</div>

				</wb-headers>
				<NavWeb></NavWeb>
			</div>
		</>
	);
}
