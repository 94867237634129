import React, { useEffect, useState } from 'react';

import { DUITheme } from '@daimler/material-ui-theme';
import { Themes } from '../utility/theme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Subcomponent } from './subcomponent/subcomponent';
import { MainPage } from './mainPage/MainPage';
import { createStyles, makeStyles } from '@material-ui/core';
import { Colors } from '../utility/theme';
import Header from './general/header/Header';
import { Footer } from '../components/general/Footer';
import { Icon } from '@workbench/core';
import { QrOverview } from './qr/QrOverview';
import { CategoryOverview } from './category/CategoryOverview';
import { SheetOverview } from './category/sheetPage/SheetOverview';

import { CATEGORY_PROPS } from '../globals/Constants';
import CookieBar from '../components/general/cookieBar/CookieBar';
import CookieModel from '../components/general/cookieModel/CookieModel';
import OilFinder from './oilFinder/OilFinder';
import { Contact } from '../components/general/contact/Contact';
import { closeCategoriesMenu } from '../utility/DialogHelper';

import Cookies from 'js-cookie';
import * as ackeeTracker from 'ackee-tracker';
import {SheetPdf} from "./category/sheetPage/SheetPdf";

// Styling
const useStyles = makeStyles(() =>
  createStyles({
    app: {
      maxWidth: '1440px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block',
    },
  })
);

export const App = () => {
  const classes = useStyles();
  const [isTracking, setIsTracking] = useState(
    Cookies.get('t_track_data') === 'true'
  );

  // useEffect(() => {
  //   isTracking &&
  //     ackeeTracker
  //       .create('https://ackee-bevo.trucks.app.corpintra.net', {
  //         detailed: true,
  //         ignoreLocalhost: true,
  //         ignoreOwnVisits: true,
  //       })
  //       .record('a7b71974-b7c5-4c5d-9384-1efc1810fec3');
  // }, [isTracking]);

  return (
    <MuiThemeProvider theme={Themes.Primary}>
      <div className={classes.app}>
        <div
          id='deactivedBackground'
          className='deactiveBackground notVisible'
          onClick={() => closeCategoriesMenu()}
        ></div>

          <BrowserRouter>
            <div>
              <Header></Header>
            </div>
            <Routes>
              <Route path='/' element={<MainPage />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/oil-finder' element={<OilFinder />} />
              <Route path='/qrcode/:productId' element={<QrOverview />} />
                <Route
                    path='/pdf/:sheetNumber/:language'
                    element={<SheetPdf />}
                />
              <Route
                path='/sheet/:sheetNumber/:language'
                element={<SheetOverview />}
              />

              {CATEGORY_PROPS.map((element, key) => {
                return (
                  <Route
                    path={`/category${element.link}/:language`}
                    element={
                      <CategoryOverview
                        description={element.description}
                        image={element.image}
                      ></CategoryOverview>
                    }
                    key={key}
                  ></Route>
                );
              })}
              <Route path='/Subcomponent' element={<Subcomponent />} />
            </Routes>
            <div>
              <Footer></Footer>
            </div>
            <CookieModel></CookieModel>
            {/*{!Cookies.get('t_disclaimer') && <CookieBar></CookieBar>}*/}
          </BrowserRouter>
      </div>
    </MuiThemeProvider>
  );
};
