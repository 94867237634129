import React, { useEffect } from 'react';
import CategoryList from './categoryList/CategoryList';
import { CategoryTable } from './categoryTable/CategoryTable';
import { HeaderBanner } from './headerBanner/HeaderBanner';
import Prolog from './prolog/Prolog';

import { closeCategoriesMenu } from '../../utility/DialogHelper';

export const CategoryOverview = (props: {
  description: string;
  image: any;
}) => {
  useEffect(() => {
    closeCategoriesMenu();
    window.scrollTo(0, 0);
  }, [props.description, props.image]);
  return (
    <div>
      <HeaderBanner
        image={props.image}
        description={props.description}
      ></HeaderBanner>
      <Prolog description={props.description}></Prolog>
      <div style={{ marginTop: '115px' }}>
        <CategoryTable description={props.description}></CategoryTable>
      </div>
      <div
        style={{ marginTop: '80px', marginRight: '14px', marginLeft: '14px' }}
      >
        <CategoryList description={props.description}></CategoryList>
      </div>
    </div>
  );
};
